import { ChangeEvent, useEffect, useState } from 'react';
// import { tripsService } from '../../services/trips.service';
import { LatLng, StopLocation, Trip, TripStopLabelStruct } from '../../store/config/types/trips.types';
import DndTimelineChart, { DndTimeLineChartItem, DndTimelineChartRow } from '../DndTimelineChart/DndTimelineChart';
import moment from 'moment-timezone';
import TripDndItemToolTip from './TripDndItemToolTip';
import TripViewTripActions from './TripViewTripActions';
import TripViewTripMetrics from './TripViewTripMetrics';
import { Courier, CustomerLocationOrder } from '../../store/config/types';
import TripDndItem from './TripDndItem';

import { Add, Edit, Refresh, SaveAlt, Visibility } from '@material-ui/icons';
import { FormControlLabel, Grid, IconButton, Switch } from '@mui/material';
import SendToToday from './SendToToday';
import DatePicker from '../DatePicker/DatePicker';
import Map from '../Map/Map';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { getStopSequence, stopToDndItem } from './helper';
import Spinner from '../Spinner/Spinner';
import { customerLabel } from '../../utils/trips.helper';
import { TripInfoPanel } from './TripInfoPanel';

interface TripViewProps {
  trips: Trip[];
  warehouses?: Warehouse[];
  unAssignedOrders: CustomerLocationOrder[];
  date: string;
  shipperId?: number;
  couriers?: Courier[];
  timezone?: string;
  computedToggle?: boolean;
  trackedToggle?: boolean;
  canComputeStops?: boolean;
  lastTripUpdate?: Date;
  labels: TripStopLabelStruct[];
  isLoading?: boolean;
  /* eslint-disable */
  onTripMenuAction?: (tripId: number | undefined, menuAction: string) => void;
  onCustomAction?: (actionParams: any, actionName: string) => void;
  onActionButtonClick?: (tripIds: number[], actionName: string) => void;
  onNeedRefresh?: () => void;
  onUpdateClicked?: () => void;
  handleDateChane?: (e: ChangeEvent<HTMLInputElement>) => void;
  /* eslint-enable */
}
export default function TripView({
  trips,
  warehouses,
  date,
  // shipperId,
  unAssignedOrders,
  couriers,
  timezone,
  computedToggle = false,
  trackedToggle = false,
  canComputeStops = false,
  lastTripUpdate,
  isLoading,
  onUpdateClicked,
  onTripMenuAction,
  onActionButtonClick,
  onCustomAction,
  handleDateChane,
  labels,
}: TripViewProps) {
  const [tripRows, setTripRows] = useState<DndTimelineChartRow[]>([]);
  const [unAssignedItems, setUnAssignedItems] = useState<DndTimeLineChartItem[]>([]);
  const [displayComputed, setDisplayComputed] = useState<boolean>(false);
  const [displayTracked, setDisplayTracked] = useState<boolean>(false);
  const [mapTripVisibility, setMapTripVisibility] = useState<{ tripId: number; visibility: boolean }[]>([]);
  const [allTripIsVisible, setAllTripIsVisible] = useState(true);
  const [highLightedStopId, setHighLightStopId] = useState(0);
  const [highLightedCustomerId, setHighLightCustomerId] = useState(0);
  const [highLightedCourierId, setHighLightCourierId] = useState(0);
  const [mapBoundPoints, setMapBoundPoints] = useState<LatLng[]>([]);

  const [mapNeedsRebound, setMapNeedsRebound] = useState(false);
  const [activeCouriers, setActiveCouriers] = useState<Courier[]>([]);
  const [tripToDisplayDetails, setTripToDisplayDetails] = useState<Trip>();

  // const [trips] = useState(tripsToShow);
  timezone && moment.tz.setDefault(timezone);

  // trips?.forEach((trip) => {
  //   trip.tripStops.forEach((stop, stopIndex) =>
  //     labels.push({
  //       tripStopId: stop.tripStopId,
  //       label: `${stopIndex ? stopIndex : 'W'}`,
  //     }),
  //   );
  // });

  const getEditableTripIds = () => {
    return trips.filter((trip) => ['NEW'].includes(trip.status)).map((trip) => trip.tripId ?? 0);
  };
  const handleActionClick = (action: string) => {
    if (onActionButtonClick !== undefined) onActionButtonClick(getEditableTripIds(), action);
  };
  const handleSendToToday = async (unAssignIdx: number) => {
    if (unAssignedItems.length > unAssignIdx) {
      if (onCustomAction !== undefined)
        onCustomAction(
          {
            packageGroupsIds: unAssignedItems[unAssignIdx].additionalInfo.packageGroupsIds,
          },
          'sendToToday',
        );
    }
  };
  // const hadleDetailsClick = (item: DndTimeLineChartItem) => {
  //   if (onCustomAction !== undefined) onCustomAction({ tripStopId: item.additionalInfo.tripStopId }, 'moreStopDetails');
  // };
  const setTripVisibilityOnMap = (tripId: number, value?: boolean) => {
    const tIndex = mapTripVisibility.findIndex((vInfo) => vInfo.tripId === tripId);
    let changed = false;
    if (tIndex < 0) {
      mapTripVisibility.push({ tripId, visibility: true });
      changed = true;
    } else if (value !== undefined) {
      changed = mapTripVisibility[tIndex].visibility != value;
      mapTripVisibility[tIndex].visibility = value;
      if (value === false) {
        setAllTripIsVisible(false);
      }
    }
    if (changed) {
      setMapTripVisibility([...mapTripVisibility]);
    }
  };

  const handleAllTripVisibilityClick = () => {
    let visibility = true;
    if (allTripIsVisible) {
      visibility = false;
    }
    trips?.forEach((trip) => setTripVisibilityOnMap(trip.tripId ?? 0, visibility));
    setAllTripIsVisible(visibility);
  };

  const extractCustomerLocationFromOrder = (order: CustomerLocationOrder): StopLocation => {
    return {
      ...order.shippingLocation,
    } as StopLocation;
  };
  const onStopTooltipOpen = (rowIndex: number, itemIndex: number, isOpen: boolean) => {
    if (isOpen) setHighLightStopId(trips[rowIndex].tripStops[itemIndex].tripStopId);
    else setHighLightStopId(0);
  };

  const handleStopHover = (stopId: number, isOver: boolean) => {
    if (isOver) {
      setHighLightStopId(stopId);
    } else {
      setHighLightStopId(0);
    }
  };

  const handleUnassignHover = (customerId: number, isOver: boolean) => {
    if (isOver) {
      setHighLightCustomerId(customerId);
    } else {
      setHighLightCustomerId(0);
    }
  };

  const onUnassignedTooltipOpen = (itemIndex: number, isOpen: boolean) => {
    if (isOpen) setHighLightCustomerId(unAssignedOrders[itemIndex].customerId);
    else setHighLightCustomerId(0);
  };

  const handleOnCourierClick = (courierId: number) => {
    setHighLightCourierId(courierId);
  };

  const handleOnItemClick = (item: DndTimeLineChartItem) => {
    setHighLightStopId(item.additionalInfo?.tripStopId ?? 0);
  };
  const handleOnItemDoubleClick = (item: DndTimeLineChartItem) => {
    const stopId = item.additionalInfo?.tripStopId ?? 0;
    const stopLocation = trips[item.additionalInfo?.tripIndex ?? 0].tripStops.find(
      (stop) => stop.tripStopId === item.additionalInfo.tripStopId,
    )?.location;
    setHighLightStopId(stopId);
    if (stopLocation) setMapBoundPoints([{ lat: stopLocation.latitude, lng: stopLocation.longitude }]);
  };
  const handleOnUnassignedItemClick = (itemIndex: number) => {
    setHighLightCustomerId(unAssignedOrders[itemIndex].customerId);
  };
  const handleTripMenuAction = (tripId: number | undefined, menuAction: string) => {
    if (menuAction == 'details') {
      const tripIndex = trips.findIndex((t) => t.tripId === tripId);
      if (tripIndex >= 0) {
        trips?.forEach((trip) => setTripVisibilityOnMap(trip.tripId ?? 0, trip.tripId === tripId));
        setAllTripIsVisible(false);
        setTripToDisplayDetails(trips[tripIndex]);
        setMapNeedsRebound(true);
      }
    } else if (onTripMenuAction) {
      onTripMenuAction(tripId, menuAction);
    }
  };

  const handleTripInfoPanelClose = () => {
    setTripToDisplayDetails(undefined);
  };

  const reBoundMap = () => {
    if (!highLightedStopId) {
      if (mapTripVisibility.some((tripInfo) => tripInfo.visibility)) {
        const allPointsOfVisibleTrips: LatLng[] = [];
        mapTripVisibility
          .filter((tripInfo) => tripInfo.visibility)
          .forEach((tripInfo) => {
            const foundedTrip = trips.find((trip) => trip.tripId === tripInfo.tripId);
            if (foundedTrip) {
              foundedTrip.tripStops?.forEach((stop) => {
                allPointsOfVisibleTrips.push({
                  lat: stop.location.latitude,
                  lng: stop.location.longitude,
                });
              });
            }
          });
        setMapBoundPoints(allPointsOfVisibleTrips);
      } else {
        if (unAssignedOrders.length > 0) {
          setMapBoundPoints(
            unAssignedOrders.map((order) => ({
              lat: order.shippingLocation?.latitude ?? order.customer?.location?.latitude,
              lng: order.shippingLocation?.longitude ?? order.customer?.location?.longitude,
            })),
          );
        } else {
          setMapBoundPoints(
            warehouses?.map((warehouse) => ({
              lat: warehouse.location.latitude,
              lng: warehouse.location.longitude,
            })) ?? [],
          );
        }
      }
    }
    setMapNeedsRebound(false);
  };

  useEffect(() => {
    const newRows: DndTimelineChartRow[] = [];

    trips.map((trip, tripIndex) => {
      setTripVisibilityOnMap(trip.tripId ?? 0);
      if (trip.tripStops) {
        const stopSequence = getStopSequence(trip.tripStops, !isToday && displayComputed);

        newRows.push({
          index: trip.tripId,
          dropable: false,
          items: stopSequence.map((stopInfo) => {
            const stopIndex = trip.tripStops.findIndex((s) => s.tripStopId === stopInfo.tripStopId);
            const stop = trip.tripStops[stopIndex];
            return stopToDndItem(
              trip,
              stop,
              tripIndex,
              stopIndex,
              highLightedStopId === stop.tripStopId,
              timezone,
              !isToday && displayComputed,
              labels,
            );
          }),
          additionalInfo: {
            colorIndex: trip.additionalData?.colorPaletteIndex ?? tripIndex,
          },
        });
      }
    });
    // newRows.sort((a, b) => {
    //   const compare = a.items[0] && b.items[0] ? a.items[0].start.unix() - b.items[0].start.unix() : 0;
    //   return compare == 0 ? (a.index || 0) - (b.index || 0) : compare;
    // });
    setTripRows(newRows);
    // setIsMapLoading(false);
  }, [trips, displayComputed, highLightedStopId]);

  useEffect(() => {
    const unAssigned: DndTimeLineChartItem[] = [];
    const isToday = moment().isSame(date, 'day');
    unAssignedOrders.forEach((deliveryOrder, dIndex) => {
      const label = customerLabel(deliveryOrder.customer, dIndex);
      unAssigned.push({
        dragable: !isToday,
        start: moment(),
        end: moment(),
        label,
        colorClass: `color-unassigned`,
        additionalInfo: {
          name: deliveryOrder.customer
            ? `${deliveryOrder.customer.firstName} ${deliveryOrder.customer.lastName}`
            : undefined,
          companyName: deliveryOrder.customer ? `${deliveryOrder.customer.companyName ?? ''}` : undefined,
          warehouseName: deliveryOrder.warehouse ? `${deliveryOrder.warehouse.name ?? ''}` : undefined,
          packageGroupsIds: deliveryOrder.packageGroups.map((packageGroup) => packageGroup.packageGroupId),
          customerId: deliveryOrder.customerId,
          shipToAddressee: deliveryOrder.shipToAddressee,
        },
        highlight: highLightedCustomerId === deliveryOrder.customerId,
      });
    });

    setUnAssignedItems(unAssigned);
    // setIsMapLoading(false);
  }, [unAssignedOrders, highLightedCustomerId]);

  useEffect(() => {
    // setIsMapLoading(true);
    handleTripInfoPanelClose();
    setMapNeedsRebound(true);
  }, [date, trips, unAssignedOrders]);

  useEffect(() => {
    if (mapNeedsRebound) reBoundMap();
  }, [mapNeedsRebound]);

  useEffect(() => {
    if (couriers && couriers.length) {
      setActiveCouriers(
        couriers.filter(
          (courier) =>
            courier.online &&
            courier.locationHistory?.length &&
            moment().isSame(courier.locationHistory[0].locationAt, 'day'),
        ),
      );
    }
  }, [couriers]);

  // if (trips.length === 0 && unAssignedItems.length === 0) return null;
  const isToday = moment(date).isSame(moment(), 'day');
  const editable = isToday || moment(date).isAfter(moment().startOf('day'));

  return (
    <div className="trips-dnd-full-page">
      <div className="trips-dnd">
        <div className="trips-dnd-title-area">
          <Grid className="trips-dnd-title-date">
            <DatePicker
              value={moment(date).format('YYYY-MM-DD')}
              hiddenLabel
              onChange={isLoading ? () => {} : handleDateChane ?? (() => {})}
              style={{ color: 'white' }}
            />
          </Grid>
          <Grid flexGrow={1} textAlign="left" pr={2} pl={2}>
            <IconButton
              size="small"
              onClick={() => {
                onCustomAction && onCustomAction({}, 'CSV');
              }}
            >
              <SaveAlt style={{ color: 'white' }} />
            </IconButton>
          </Grid>
          <Grid className="trips-dnd-title-actions">
            {isToday && (
              <>
                <IconButton size="small" onClick={onUpdateClicked}>
                  <Refresh style={{ color: 'white' }} />
                </IconButton>
                <span>Update @ {moment(lastTripUpdate).format('h:mm a')}</span>
              </>
            )}
            {trackedToggle && (
              <FormControlLabel
                control={
                  <Switch
                    value={displayTracked}
                    title="Display Tracked Points"
                    size="small"
                    onChange={(e) => {
                      setDisplayTracked(e.currentTarget.checked);
                    }}
                  />
                }
                label={'Actual'}
                labelPlacement="start"
              />
            )}
            {computedToggle && (
              <FormControlLabel
                disabled={editable}
                control={
                  <Switch
                    value={displayComputed}
                    title="Display Computed"
                    size="small"
                    onChange={(e) => {
                      setDisplayComputed(e.currentTarget.checked);
                    }}
                  />
                }
                label={'Computed'}
                labelPlacement="start"
              />
            )}
            {editable && (
              <IconButton
                size="small"
                onClick={() => {
                  handleActionClick('edit');
                }}
              >
                <Edit style={{ color: 'white' }} />
              </IconButton>
            )}
            {editable && unAssignedItems.length > 0 && (
              <IconButton
                size="small"
                onClick={() => {
                  handleActionClick('create');
                }}
              >
                <Add style={{ color: 'white' }} />
              </IconButton>
            )}
          </Grid>
        </div>
        <div className="info-wrapper">
          <div className="trips-panel">
            <TripInfoPanel
              trip={tripToDisplayDetails}
              onClose={handleTripInfoPanelClose}
              couriers={couriers}
              displayComputed={displayComputed}
            />
          </div>
          <div className="trips-wrapper">
            <div className={`map-area${isLoading ? ' loading' : ''}`}>
              <div className="spinner">
                <Spinner />
              </div>
              <Map
                trips={trips}
                isLoading={isLoading}
                labels={labels}
                warehouses={warehouses}
                visibleTripsIds={mapTripVisibility
                  .filter((tripInfo) => tripInfo.visibility)
                  .map((tripInfo) => tripInfo.tripId)}
                customersLocations={unAssignedOrders.map((order) => extractCustomerLocationFromOrder(order))}
                displayTracked={displayTracked}
                couriers={isToday ? activeCouriers : []}
                highlightStopId={highLightedStopId}
                highlightCustomerId={highLightedCustomerId}
                highlightedCourierId={highLightedCourierId}
                onStopHover={handleStopHover}
                onCustomerHover={handleUnassignHover}
                locationsDistanceType={!isToday && displayComputed ? 'track' : 'router'}
                boundPoints={mapBoundPoints}
                onCourierClick={handleOnCourierClick}
                onMapClick={() => {
                  setHighLightCustomerId(0);
                  setHighLightStopId(0);
                  setHighLightCourierId(0);
                }}
                onStopClick={(stopId) => {
                  setHighLightStopId(stopId);
                }}
              />
            </div>
            <div className="chart-area">
              <DndTimelineChart
                additionAreaWidth={250}
                rowEndAdditionalWidth={100}
                rows={tripRows}
                recycleItems={unAssignedItems}
                showCurrentTime
                itemRender={(item) => <TripDndItem item={item} />}
                itemToolTipRender={(item, rowIndex) => <TripDndItemToolTip item={item} showTimes={rowIndex !== -1} />}
                timebandAdditionalAreaRender={() => {
                  return (
                    <div className="courier-area">
                      <span className="visible-on-map">
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleAllTripVisibilityClick();
                          }}
                        >
                          <Visibility
                            style={{
                              color: allTripIsVisible ? 'lightgray' : 'GrayText',
                              width: 20,
                            }}
                          />
                        </IconButton>
                      </span>
                    </div>
                  );
                }}
                additionalAreaRender={(rowIndex) => {
                  const tripIndex = tripRows[rowIndex].index;
                  const trip = tripIndex ? trips.find((t) => t.tripId === tripIndex) : undefined;
                  return trip ? (
                    <TripViewTripActions
                      trip={trip}
                      couriers={couriers}
                      timezone={timezone}
                      onMenuSelect={handleTripMenuAction}
                      canComputeStops={canComputeStops && !editable && trip.status === 'FINISHED'}
                      visible={
                        mapTripVisibility.find((tripInfo) => tripInfo.tripId === trip.tripId)?.visibility ?? false
                      }
                      onVisibilityChange={setTripVisibilityOnMap}
                    />
                  ) : (
                    <></>
                  );
                }}
                recycleAreaRender={
                  unAssignedItems.length > 0 ? (isDragStarts) => <SendToToday isDragStarts={isDragStarts} /> : undefined
                }
                rowEndRender={(rowIndex) => {
                  const tripIndex = tripRows[rowIndex].index;
                  const trip = tripIndex ? trips.find((t) => t.tripId === tripIndex) : undefined;
                  const itemsCount = tripRows[rowIndex].items.length;
                  const endTime = displayComputed
                    ? moment(trip?.computedReturnAt)
                    : trip?.isRoundTrip
                    ? moment(trip.returnAt)
                    : moment(tripRows[rowIndex].items[itemsCount - 1]?.end);
                  const totalMinutes = itemsCount > 1 ? endTime.diff(tripRows[rowIndex].items[0].start, 'minutes') : 0;
                  const hours = Math.floor(totalMinutes / 60);
                  const minutes = totalMinutes % 60;
                  const totalTime = ` ${hours > 0 ? hours + 'h' : ''}${minutes > 0 ? minutes + "'" : ''}`;

                  return (
                    <TripViewTripMetrics
                      trip={trip}
                      totalTime={totalTime}
                      displayComputed={!isToday && displayComputed}
                    />
                  );
                }}
                timezone={timezone}
                displayRecycle={unAssignedItems.length > 0}
                onBeforeDropToCustomComponent={(sourceRowId, sourceItemIndex, destRowId) => {
                  if (sourceRowId === 'unassigned' && destRowId == 'sendToToday') {
                    handleSendToToday(sourceItemIndex);
                  }
                  return true;
                }}
                onTooltipOpen={onStopTooltipOpen}
                onUnassignedTooltipOpen={onUnassignedTooltipOpen}
                onItemClick={handleOnItemClick}
                onItemDoubleClick={handleOnItemDoubleClick}
                onUnassignedItemClick={handleOnUnassignedItemClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
