import { Customer, CustomerLocationOrder, DeliveryOrder, LocationHistory, Vehicle } from '../store/config/types';
import { VehicleEnum } from '../store/config/enums/vehicle.enum';
import moment from 'moment-timezone';

export const vehicleListConcat = (vehicle: Vehicle): string => {
  if (vehicle) {
    switch (vehicle.vehicleType) {
      case VehicleEnum.BIKE:
      case VehicleEnum.WALKER:
        return VehicleEnum[vehicle.vehicleType];
      default:
        let returnValue = '';
        returnValue += VehicleEnum[vehicle.vehicleType || VehicleEnum.CAR] + '-';
        returnValue += vehicle.model + '-';
        returnValue += vehicle.color + '-';
        returnValue += vehicle.licensePlate;
        return returnValue;
    }
  } else {
    return 'N/D';
  }
};

export const googleMapsLink = (location: LocationHistory): string => {
  if (location) {
    const latitude = location.latitude;
    const longitude = location.longitude;
    return `https://maps.google.com/?q=${latitude},${longitude}`;
  } else {
    return 'N/D';
  }
};

export const maskPhone = (phone: string | undefined) => {
  if (phone?.length === 10) {
    return `(${phone[0]}${phone[1]}${phone[2]}) ${phone[3]}${phone[4]}${phone[5]}-${phone[6]}${phone[7]}${phone[8]}${phone[9]}`;
  }
};

export const formatTime = (time: string | null): string | null => {
  if (time) {
    const timeObj = new Date(' 2000/01/01 ' + time);
    const hours = (timeObj.getHours() > 12 ? timeObj.getHours() - 12 : timeObj.getHours()).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const amPm = timeObj.getHours() >= 12 ? ' pm' : ' am';
    return (
      hours +
      ':' +
      timeObj.getMinutes().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      amPm
    );
  }
  return null;
};

export const formatTimeWindow = (from: string | null, to: string | null, replaceNullWithText: boolean = false) => {
  if (!from && !to) return replaceNullWithText ? <div>Whole day</div> : null;
  if (replaceNullWithText)
    return (
      <div className="time-window time-window-text">
        {(from ? formatTime(from) + (to ? ' to' : '') : 'Before') + ' ' + (to ? formatTime(to) : 'and later')}
      </div>
    );
  else
    return (
      <>
        <div className="time-window time-window-from">{formatTime(from ?? '00:00:00')}</div>
        <div className="time-window time-window-to">{formatTime(to ?? '23:59:00')}</div>
      </>
    );
};

export const formatDeliveryOrderTimeWindow = (deliveryOrder: DeliveryOrder, replaceNullWithText: boolean = false) => {
  const { from, to } = deliveryOrder.packageGroups.reduce(
    ({ from, to }, packageGroup) => {
      const pFrom = from
        ? packageGroup.timeWindowFrom && from > packageGroup.timeWindowFrom
          ? packageGroup.timeWindowFrom
          : from
        : packageGroup.timeWindowFrom ?? null;
      const pTo = to
        ? packageGroup.timeWindowTo && to < packageGroup.timeWindowTo
          ? packageGroup.timeWindowTo
          : to
        : packageGroup.timeWindowTo ?? null;
      return { from: pFrom, to: pTo };
    },
    { from: null, to: null } as { from: string | null; to: string | null },
  );
  return formatTimeWindow(from, to, replaceNullWithText);
};

export const replaceVariableNameByItsValue = (body: string, variablesObject: any): string => {
  let replacedStr = body;
  if (variablesObject && Object.keys(variablesObject).length) {
    let variables = body.match(/{[a-zA-Z0-9._]+}/g);
    if (Array.isArray(variables)) {
      variables.forEach((variable) => {
        const variableIndexes = variable
          .substring(1, variable.length - 1)
          .split('.')
          .map((indexName) => indexName.trim());
        let value: any = variablesObject;
        let idx = 0;
        do {
          value = value[variableIndexes[idx++]] ?? null;
        } while (idx < variableIndexes.length);
        if (value) replacedStr = replacedStr.replaceAll(variable, value.toString());
      });
    }
  }
  return replacedStr;
};

export const formatDate = (date: string | Date, calendarDate: boolean = false): string => {
  let dateString = moment.utc(date).format('MM/DD/YYYY');
  if (!calendarDate) return dateString;
  const currentDate = new Date();
  const targetDate = new Date(date);

  if (date)
    if (currentDate.getFullYear() == targetDate.getFullYear()) {
      if (currentDate.getMonth() == targetDate.getMonth()) {
        if (currentDate.getDate() == targetDate.getDate()) {
          return 'Today ' + moment.utc(targetDate).format('h:mm a');
        } else if (currentDate.getDate() + 1 == targetDate.getDate()) {
          return 'Tomorrow';
        }
        return moment.utc(targetDate).format('Do');
      }
      return moment.utc(targetDate).format('MMM Do');
    }
  return dateString;
};

export const FormatDateByTimezone = (date: Date, timezone?: string) => {
  if (timezone) moment.tz.setDefault(timezone);
  return moment(date).format('MM/DD/YY');
};

export const FormatRelatedDateTimeByTimezone = (
  date: Date | undefined,
  pivotDate: Date | undefined,
  timezone?: string,
) => {
  if (timezone) moment.tz.setDefault(timezone);
  return (moment(date).isSame(pivotDate, 'day') ? '' : moment(date).format('MM/DD - ')) + moment(date).format('HH:mm');
};

export const calculateDeliveryExpectedTime = (deliveryOrder: CustomerLocationOrder, timezone?: string) => {
  let timeFrom: string | null = null;
  let timeTo: string | null = null;
  timezone && moment.tz.setDefault(timezone);
  const today = moment();
  deliveryOrder.packageGroups.forEach((packageGroup) => {
    timeFrom =
      timeFrom && packageGroup.timeWindowFrom
        ? moment(packageGroup.timeWindowFrom, 'HH:mm:ss') < moment(timeFrom, 'HH:mm:ss')
          ? packageGroup.timeWindowFrom ?? null
          : timeFrom
        : packageGroup.timeWindowFrom ?? null;
    timeTo =
      timeTo && packageGroup.timeWindowTo
        ? moment(packageGroup.timeWindowTo, 'HH:mm:ss') < moment(timeTo, 'HH:mm:ss')
          ? packageGroup.timeWindowTo ?? null
          : timeTo
        : packageGroup.timeWindowTo ?? null;
  });
  const timeWindow = formatTimeWindow(timeFrom, timeTo);
  if (moment(deliveryOrder.deliveryExpectedAt).isSame(today, 'date') && timeWindow) return timeWindow;
  else return moment(deliveryOrder.deliveryExpectedAt).format('MM/DD/YYYY');
};

export const descriptionalTime = (date?: Date, timezone?: string) => {
  if (!date) return 'Time not available';
  const minutes = moment().diff(date, 'minute');
  if (minutes < 1) return 'Now';
  if (minutes < 2) return 'A minute ago';
  if (minutes < 60) return `${minutes} minutes ago`;
  if (timezone) moment.tz.setDefault(timezone);
  if (moment().isSame(date, 'day')) {
    const hours = moment().diff(date, 'hours');
    return `${hours} hour${hours >= 2 ? 's' : ''} ago`;
  } else {
    return `${moment(date).format('DD/MM/YY')}`;
  }
};

export const fixNumber = (n: number = 0) => +n.toFixed(2);

export const strOrAlt = (str: string | undefined, alt: string | undefined, defaultStr?: string) =>
  str ? str : alt ? alt : defaultStr;

export const fixBigNumber = (n: number = 0) => {
  let postfix = '';
  if (n >= 1000000) {
    n /= 1000000;
    postfix = 'M';
  } else if (n >= 1000) {
    n /= 1000;
    postfix = 'K';
  }
  return `${n.toFixed(1)} ${postfix}`;
};

export const getCustomerDisplayName = (customer: Customer) => {
  return customer.companyName ? customer.companyName : `${customer.firstName ?? ''} ${customer.lastName ?? ''}`;
};

export const formatMinutes = (minutes: number) => {
  if (minutes < 60) {
    return minutes.toFixed(0) + 'min';
  } else {
    const hours = Math.floor(minutes / 60);
    const remaining = minutes - hours * 60;
    return remaining == 0 ? hours + 'h' : hours + ':' + remaining.toFixed(0).padStart(2, '0');
  }
};

export function convertConstantLiteralToNormal(input?: string): string {
  if (input) {
    let words = input.toLowerCase().split('_');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
  } else return '';
}
