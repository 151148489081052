import { Marker } from '@react-google-maps/api';
import { TripParking } from '../../store/config/types';

interface ParkingMarkerProps {
  parking: TripParking;
  // eslint-disable-next-line
  onHover?: (customerId: number, isOver: boolean) => void;
}
export default function ParkingMarker({ parking }: ParkingMarkerProps) {
  // const [hover, setHover] = useState(false);
  let fillColor = 'blue';
  switch (parking.additionalData?.type) {
    case 'UNRELATED':
      switch (parking.additionalData.purpose) {
        case 'REST':
          fillColor = 'green';
          break;
        case 'GENERAL':
          fillColor = '#447';
          break;
        default:
          fillColor = '#c00';
      }
      break;
  }
  return (
    <>
      <Marker
        position={{ lat: parking.latitude, lng: parking.longitude }}
        // onMouseOver={() => setHover(true)}
        // onMouseOut={() => setHover(false)}
        icon={{
          path: 'M 0 0 L 50 0 C 50 0 50 14.274 50 17.751 C 50 17.751 65.794 35.219 64.95 34.685 C 50.464 25.516 50 25 50 25 L 0 25 L 0 0 Z',
          fillColor,
          strokeColor: 'black',
          strokeWeight: 1,
          fillOpacity: 1,
          anchor: new window.google.maps.Point(65, 35),
          labelOrigin: new window.google.maps.Point(25, 12),
          scale: 0.5,
        }}
        label={{
          text: `${parking.duration}'`,
          fontSize: '10px',
          color: 'white',
        }}
      >
        {/* {hover && (Boolean(parking.additionalData.dwellCost) || Boolean(parking.additionalData.transitShareCost)) && (
          <InfoBox>
            <div className="map-trip-parking-info">
              {Boolean(parking.additionalData.dwellCost) && (
                <div className="cost-dwell">Dwell: ${parking.additionalData.dwellCost.toFixed(2)}</div>
              )}
              {Boolean(parking.additionalData.transitShareCost) && (
                <div className="cost-transit">Transit: ${parking.additionalData.transitShareCost.toFixed(2)}</div>
              )}
            </div>
          </InfoBox>
        )} */}
      </Marker>
    </>
  );
}
