import { Button, Card, CardActions, Chip, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { Courier, Trip } from '../../store/config/types';
import { Close, Event, Home, LocationOn } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import './TripInfoPanel.scss';
import tripsService from '../../services/trips.service';
import { getTripTimeline, TripTimelineItem } from './helper';
import moment from 'moment-timezone';
import { convertConstantLiteralToNormal, formatMinutes } from '../../utils/string.helpers';

interface TripInfoPanelProps {
  trip?: Trip;
  onClose?: () => void;
  couriers?: Courier[];
  timezone?: string;
  displayComputed?: boolean;
}

export function TripInfoPanel({ trip, onClose, couriers, timezone, displayComputed }: TripInfoPanelProps) {
  const [courier, setCourier] = useState<Courier>();
  const [timeline, setTimeline] = useState<TripTimelineItem[]>([]);
  const [totalCost, setTotalCost] = useState(0);
  const [totalMiles, setTotalMiles] = useState(0);

  const handleClose = () => {
    if (onClose) onClose();
  };
  useEffect(() => {
    if (trip) {
      setCourier(couriers?.find((c) => c.courierId == trip.courierId));
      const tripParkings = tripsService.LocationsPathService.getTripParkings(trip.tripId ?? 0);
      setTimeline(getTripTimeline(trip, tripParkings, displayComputed));
      const tCost = tripParkings.reduce((total, p, pIdx) => {
        if (pIdx < tripParkings.length - 1) {
          return total + (p.additionalData?.dwellCost ?? 0) + (p.additionalData?.transitShareCost ?? 0);
        } else {
          return total;
        }
      }, 0);
      setTotalCost(tCost);
      setTotalMiles(
        displayComputed ? (trip.computedMiles ?? 0) + (trip.computedReturnMiles ?? 0) : trip.totalMiles ?? 0,
      );
    } else {
      setCourier(undefined);
      setTimeline([]);
      setTotalCost(0);
      setTotalMiles(0);
    }
  }, [trip, displayComputed]);
  if (timezone) moment.tz.setDefault(timezone);
  let timelineDate = moment(trip?.assignedAt);
  return (
    <Collapse orientation="horizontal" in={Boolean(trip)}>
      <div className="width-provider"></div>
      <div className="trip-info-panel">
        <Grid container flexDirection={'column'} flexWrap="nowrap">
          <Grid item p={1}>
            <Grid container flexDirection={'row'} alignItems="center">
              <Grid item flexGrow={1}>
                <Grid container flexDirection="column">
                  <Typography variant="subtitle1">
                    {courier?.firstName} {courier?.lastName}
                  </Typography>
                  <Typography variant="subtitle2">
                    {trip?.vehicle?.vehicleType} {trip?.vehicle?.licensePlate}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Chip label={convertConstantLiteralToNormal(trip?.status ?? '')} size="small" />
              </Grid>
              <Grid item ml={1}>
                <IconButton size="small" onClick={handleClose}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container>
              {Boolean(totalCost) && (
                <Grid item flexGrow={1}>
                  <Typography variant="caption" color="GrayText">
                    Total Cost: ${totalCost.toFixed(0)}
                  </Typography>
                </Grid>
              )}
              {Boolean(totalMiles) && (
                <Grid item pr={1}>
                  <Typography variant="caption" color="GrayText">
                    {totalMiles.toFixed(0)} Miles
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item flexGrow={1} p={1} className="trip-stops-timeline" flexDirection="column">
            {timeline.map((item, index) => {
              let typeColor: any = 'default';
              if (item.info.purpose == 'UNKNOWN') {
                typeColor = 'error';
              }
              let day = '';
              if (!timelineDate.isSame(item.arrival, 'day')) {
                timelineDate = moment(item.arrival);
                day = timelineDate.format('MM/DD');
              }
              if (index == timeline.length - 1 && item.info.type != 'CUSTOMER') {
                return (
                  <Grid container justifyContent="center" alignItems="end" mt={2} mb={4}>
                    <Home style={{ color: 'silver' }} />
                    <Typography variant="caption" color="GrayText">
                      {day} {moment(item.arrival).format('h:mm a')}
                    </Typography>
                  </Grid>
                );
              } else {
                return (
                  <Grid key={index} m={1} mt={4}>
                    {Boolean(day) && (
                      <Grid container justifyContent="center" alignItems="center" mt={4} mb={1}>
                        <Event style={{ color: 'silver' }} />
                        <Typography variant="caption" color="GrayText">
                          {day}
                        </Typography>
                      </Grid>
                    )}

                    <Card style={{ marginLeft: 16, paddingLeft: 16, position: 'relative', overflow: 'visible' }}>
                      {item.stopNumber != undefined && (
                        <span className="stop-number">{item.stopNumber ? item.stopNumber : 'W'}</span>
                      )}
                      <Grid container p={1} pb={0}>
                        <Grid item flexGrow={1}>
                          <Typography variant="caption" color="gray">
                            {moment(item.arrival).format('h:mm a')}
                          </Typography>
                        </Grid>
                        {item.duration && (
                          <Grid item>
                            <Typography variant="caption" color="gray">
                              {formatMinutes(item.duration)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container p={1}>
                        <Grid item flexGrow={1}>
                          <Typography variant="body1">{item.info.title}</Typography>
                        </Grid>
                        <Grid item>
                          <Chip
                            label={convertConstantLiteralToNormal(item.info.purpose)}
                            size="small"
                            color={typeColor}
                          />
                        </Grid>
                      </Grid>
                      {item.info.address && (
                        <Grid container p={1}>
                          <Grid item>
                            <Grid container flexDirection="row" flexWrap="nowrap">
                              <Grid item>
                                <LocationOn style={{ color: 'silver' }} />
                              </Grid>
                              <Grid item flexGrow={1}>
                                <Typography variant="body2" color="GrayText">
                                  {item.info.address}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container p={1}>
                        {Boolean(item.info.dwellCost) && (
                          <Grid item flexGrow={1}>
                            <Typography variant="caption">
                              Stop Cost: ${((item.info.dwellCost ?? 0) + (item.info.transitCost ?? 0)).toFixed(0)}
                            </Typography>
                          </Grid>
                        )}
                        {Boolean(item.info.allocatedCost) && (
                          <Grid item>
                            <Typography variant="caption">
                              Allocated Cost: ${(item.info.allocatedCost ?? 0).toFixed(0)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      {item.info.purpose == 'UNKNOWN' && (
                        <CardActions>
                          <Button>Search Nearby</Button>
                        </CardActions>
                      )}
                    </Card>
                  </Grid>
                );
              }
            })}
          </Grid>
        </Grid>
      </div>
    </Collapse>
  );
}
